<template>
  <figure
    component-name="card-media"
    class="flex flex-col items-stretch gap-md"
  >
    <wrapper-actions
      :asset="asset"
      :use-href="$attrs['use-href'] || undefined"
      :use-favourite="
        $attrs['use-favourite'] ? { img_id: asset.wp_id } : undefined
      "
      :use-moodboard="
        $attrs['use-moodboard'] ? { img_id: asset.wp_id } : undefined
      "
      :use-gallery="$attrs['use-gallery'] || undefined"
      :use-remove="$attrs['use-remove'] ? { img_id: asset.wp_id } : undefined"
      :use-download="$attrs['use-download'] || undefined"
      class="w-full"
    >
      <wrapper-reveal reveal="fade-from-bottom" class="w-full">
        <media
          v-bind="asset"
          :class="$attrs.assetClass || undefined"
          :pretty_name="asset.pretty_name"
          :use-bg="false"
        />
      </wrapper-reveal>
    </wrapper-actions>

    <figcaption class="w-full">
      <wrapper-reveal
        v-if="useCaption && asset.alt"
        reveal="fade-from-right"
        class="w-3/4"
      >
        <div
          v-html="asset.alt"
          class="text-body-small first-letter:uppercase text-skin-muted max-w-full"
        ></div>
      </wrapper-reveal>

      <wrapper-reveal v-if="notAvailable" reveal="fade-from-0 w-full">
        <div
          v-html="$t.labels.not_available"
          class="text-body-small/none text-skin-danger"
        ></div>
      </wrapper-reveal>
    </figcaption>
  </figure>
</template>

<script setup>
const props = defineProps({
  asset: {
    type: Object,
    required: false,
  },
  useCaption: {
    type: Boolean,
    required: false,
    default: true,
  },
  notAvailable: { type: Boolean, required: false, default: false },
});
</script>
