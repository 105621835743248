<template>
  <div
    component-name="grid-media"
    class="w-full"
    :class="{
      'grid-fancy-layout': layout === 'fancy',
      'grid grid-cols-12 gap-ui laptop:gap-y-3xl': layout === 'regular',
    }"
  >
    <card-media
      v-for="(item, index) in items"
      :asset="{
        ...item.asset,
        sizes,
        loading:
          loading === 'lazy' || (lazyIndex > 0 && index > lazyIndex)
            ? 'lazy'
            : 'auto',
      }"
      :use-href="$attrs['use-href'] && item.button?.action?.to ? item.button.action.to : undefined"
      :use-gallery="$attrs['use-gallery'] || undefined"
      :use-favourite="$attrs['use-favourite'] ? { img_id: item.asset.wp_id } : undefined"
      :use-moodboard="$attrs['use-moodboard'] ? { img_id: item.asset.wp_id } : undefined"
      :use-download="$attrs['use-download'] || undefined"
      :use-remove="$attrs['use-remove'] || undefined"
      :not-available="item.notAvailable || undefined"
      :class="{
        'col-span-full tablet:col-span-6 laptop:col-span-4':
          layout === 'regular',
      }"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  layout: {
    type: String,
    required: false,
    validator: function (value) {
      return ["fancy", "regular"].indexOf(value) !== -1;
    },
    default: "fancy",
  },
  sizes: {
    type: Object,
    required: false,
    default: () => {
      return {
        base: 600,
        phablet: 1000,
        tablet: 650,
        laptop: 450,
        desktop: 600,
        extdesktop: 800,
      };
    },
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  lazyIndex: {
    type: Number,
    required: false,
    validator: (value) => {
      return value >= 0;
    },
    default: 5,
  },
});
</script>
